const Clipboard = require('clipboard');

$(function () {
  // Toggle for User Drop Down Menu
  $(document).on('click', '#user-menu', function (e) {
    e.preventDefault();
    $(this).next('.drop-down').toggle();
  });

  // Flash button
  $(document).on('click', '#error-close', function (e) {
    e.preventDefault();

    $('.flash').fadeOut('slow', function () {
      $(this).remove();
    });
  });

  $(document).on('click', 'a[data-submit]', function (event) {
    event.preventDefault();

    const submitRef = $(this).data('submit');
    const form = document.querySelector(`#${submitRef}`);
    Rails.fire(form, 'submit');

    return false;
  });

  $(document).on('click', 'input[type=radio]', function (event) {
    event.preventDefault();

    const submitRef = $(this).data('submit');
    if (submitRef) {
      const form = document.querySelector(`#${submitRef}`);
      Rails.fire(form, 'submit');
      return false;
    }

    return true;
  });

  $(document).on('click', 'button[data-submit]', function (event) {
    event.preventDefault();

    const submitRef = $(this).data('submit');
    const form = document.querySelector(`#${submitRef}`);
    form.submit();

    return false;
  });

  $(document).on('click', 'button[data-link]', function (event) {
    event.preventDefault();

    const link = $(this).data('link');
    window.location.href = link;

    return false;
  });

  $(document).on('click', 'a[data-file]', function (event) {
    const file = $(this).data('file');
    const input = document.querySelector(`#${file}`);
    input.click();

    return false;
  });

  $(document).on('change', 'input[type=checkbox]', function (event) {
    const submitRef = $(this).data('submit');
    if (submitRef) {
      const form = document.querySelector(`#${submitRef}`);
      Rails.fire(form, 'submit');
      return false;
    }

    return true;
  });

  $(document).on('change', 'select[data-submit]', function (event) {
    event.preventDefault();

    const submitRef = $(this).data('submit');
    const form = document.querySelector(`#${submitRef}`);
    Rails.fire(form, 'submit');

    return false;
  });

  $(document).on('change', 'input[type=file]', function (event) {
    const imageRef = $(this).data('image');
    if (imageRef) {
      const image = document.querySelector(`#${imageRef}`);

      const thumbnailRef = $(this).data('thumbnail');
      if (thumbnailRef) {
        image.src = thumbnailRef;
      } else {
        const file = event.target.files[0];

        let reader = new FileReader();
        reader.onloadend = () => {
          image.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }

    const submitRef = $(this).data('submit');
    if (submitRef) {
      const form = document.querySelector(`#${submitRef}`);
      Rails.fire(form, 'submit');
    }

    return false;
  });

  var clipboard = new Clipboard('a.copy');
  clipboard.on('success', function (e) {
    alert('Copied to clipboard');
  });

  clipboard.on('error', function (e) {
    console.error('Action:', e.action);
    console.error('Trigger:', e.trigger);
    alert(`Copy failed`);
  });
});
