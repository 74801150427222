$(function () {
  const modal_holder_selector = '#modal-holder';

  $(document).on('click', 'a[data-modal]', function () {
    const location = $(this).attr('href');
    $.get(location, (data) => {
      $(modal_holder_selector).html(data);
      window.scrollTo(0, 0);
    });
    return false;
  });

  $(document).on('click', 'button[data-modal]', function () {
    const location = $(this).data('modal');
    $.get(location, (data) => {
      $(modal_holder_selector).html(data);
      window.scrollTo(0, 0);
    });
    return false;
  });

  $(document).on('click', 'a[data-dismiss]', function () {
    $(modal_holder_selector).empty();
    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');

    if (url) {
      window.location = url;
    } else {
      const modal = $(data).find('body').html();
      $(modal_holder_selector).html(modal);
    }

    return false;
  });
});
