require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/sortable');

$(document).on('turbolinks:load', () => {
  $('.sortable').sortable({
    handle: '.handle',
    update: function (e, ui) {
      Rails.ajax({
        url: $(this).data('url'),
        type: 'PATCH',
        data: $(this).sortable('serialize', { expression: /(.+)[=](.+)/ }),
      });
    },
  });
});
