$(function () {
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyCforPRWfe9jtgX3n7agVCE3zWZDB-VmIU',
    authDomain: 'masters-program-948b2.firebaseapp.com',
    databaseURL: 'https://masters-program-948b2.firebaseio.com',
    projectId: 'masters-program-948b2',
    storageBucket: 'masters-program-948b2.appspot.com',
    messagingSenderId: '244908650822',
    appId: '1:244908650822:web:1efb20ba82241281e6f547',
    measurementId: 'G-1P2GVXLBY3',
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  firebase.auth().onAuthStateChanged(function (user) {
    updateSignInButtonUI();
    resetReCaptcha();
  });

  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
    size: 'invisible',
    callback: function (response) {
      signInWithPhoneNumber();
    },
  });

  recaptchaVerifier.render().then(function (widgetId) {
    window.recaptchaWidgetId = widgetId;
    updateSignInButtonUI();
  });

  $(document).on('click', '#sign-in-button', function (event) {
    signInWithPhoneNumber();
    return false;
  });

  $(document).on('click', '#verify-code-button', function (event) {
    verifyConfirmationCode();
    return false;
  });

  $(document).on('keyup', '#phone', function (event) {
    updateSignInButtonUI();
  });

  $(document).on('change', '#phone', function (event) {
    updateSignInButtonUI();
  });

  $(document).on('keyup', '#code', function (event) {
    updateVerifyCodeButtonUI();
  });

  $(document).on('change', '#code', function (event) {
    updateVerifyCodeButtonUI();
  });
});

function getCodeFromUserInput() {
  return document.getElementById('code').value;
}

function getPhoneNumberFromUserInput() {
  return sanitizePhone(document.getElementById('phone').value);
}

function updateSignInButtonUI() {
  document.getElementById('sign-in-button').disabled = !isPhoneNumberValid() || !!window.signingIn;
}

function updateVerifyCodeButtonUI() {
  document.getElementById('verify-code-button').disabled = !!window.verifyingCode || !getCodeFromUserInput();
}

function isPhoneNumberValid() {
  var pattern = /^\+[0-9\s\-\(\)]+$/;
  var phone = getPhoneNumberFromUserInput();
  return phone.search(pattern) !== -1;
}

function resetReCaptcha() {
  if (typeof grecaptcha !== 'undefined' && typeof window.recaptchaWidgetId !== 'undefined') {
    grecaptcha.reset(window.recaptchaWidgetId);
  }
}

function signInWithPhoneNumber() {
  window.signingIn = true;
  updateSignInButtonUI();

  const phone = getPhoneNumberFromUserInput();
  const appVerifier = window.recaptchaVerifier;
  firebase
    .auth()
    .signInWithPhoneNumber(phone, appVerifier)
    .then(function (confirmationResult) {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      window.signingIn = false;

      updateSignInButtonUI();
      updateVerifyCodeButtonUI();
      resetReCaptcha();

      $('#sign-in-button').hide();
      $('#pin').show();
      $('#code').focus();
      $('#verify-code-button').show();
    })
    .catch(function (error) {
      window.signingIn = false;

      var message;
      if (error.code == 'auth/invalid-phone-number') {
        message = 'Invalid phone number';
      } else {
        message = error.message;
      }

      updateSignInButtonUI();
      updateVerifyCodeButtonUI();
      resetReCaptcha();

      $('.error-message').html(message);
      $('#pin').hide();
      $('#sign-in-button').show();
      $('#verify-code-button').hide();
    });
}

function verifyConfirmationCode() {
  window.verifyingCode = true;
  updateVerifyCodeButtonUI();

  const code = getCodeFromUserInput();
  confirmationResult
    .confirm(code)
    .then(function (result) {
      window.verifyingCode = false;
      updateVerifyCodeButtonUI();

      const user = result.user;
      user.getIdToken().then(function (token) {
        const phone = sanitizePhone($('#phone').val());
        $('#session_phone').val(phone);
        $('#session_token').val(token);

        const form = $('#session-form');
        form.submit();
      });
    })
    .catch(function (error) {
      var message;
      if (error.code == 'auth/invalid-verification-code') {
        message = 'Incorrect verification code';
      } else {
        message = error.message;
      }

      window.verifyingCode = false;
      updateSignInButtonUI();
      updateVerifyCodeButtonUI();

      $('.error-message').html(message);
      $('#pin').hide();
      $('#sign-in-button').show();
      $('#verify-code-button').hide();
    });
}

function sanitizePhone(phone) {
  if (phone.length == 0) return '';

  if (phone.indexOf('+') == -1) {
    return `+1${phone}`;
  } else if (phone.indexOf('1') == 0) {
    return `+${phone}`;
  }

  return phone;
}
